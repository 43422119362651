*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html{
  font-size: 100%;
}
body{
  background: rgba(0,0,0,0.7);
}
.container{
  display: block;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(25deg,rgba(200,150,200),rgba(25,255,255,0.4));
}
.profile{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-width: 200px;
  min-height: 300px;
  width: auto;
  height: auto;
}
.profile img{
  border-radius: 50%;
  width: 150px;
  height: 150px;
}
.profile #name{
  font-size: 1.5rem;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-weight: 600;
  color: rgba(255,255,255,0.5);
  animation:fade 4s linear 1;
}
@keyframes fade{
  0%{opacity: 0;}
  50%{opacity: 0;}
  100%{opacity: 1.0;}
}

.profile #name em{
  font-size: 1.5rem;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-weight: 600;
  color: rgba(21,25,200,0.7);
}
.profile #animator{
  max-width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.profile #animator::after{
  color: rgba(25,200,200,0.8);
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  animation: animate 1.5s alternate 1;
  content: 'About Me';
  margin: 1vh;
  background: rgba(0,0,0,0.7);
  padding: 1vh;
  border-radius: 12px;


}
@keyframes animate{
  0%{content: 'A';}
  15%{content: 'Ab';}
  30%{content: 'Abo';}
  45%{content: 'Abou';}
  55%{content: 'About';}
  75%{content: 'About ';}
  85%{content: 'About M';}
  100%{content: 'About Me';}

}
.profile #info{
  font-size: 1.5rem;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-weight: 600;
  color: rgba(200,0,0,0.7);
  border-radius: 30px;
  animation:fade 7s linear 1;
  background: rgba(255,255,255,0.1);
  border-radius: 0px 30px 0px 30px;
  padding: 2vh;
  font-size: 1.5rem;
  min-width: 350px;
  max-width: 80%;
  width: auto;
}
@keyframes fade{
  0%{opacity: 0;}
  50%{opacity: 0;}
  100%{opacity: 1.0;}
}

.profile #info em{
  font-size: 1.5rem;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-weight: 600;
  color: rgba(21,25,20,0.7);
  font-size: 1rem;
}
#social{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 2vh;
  animation:fade 10s linear 1;
}
#social span{
  font-size: 1.2rem;
  border: 2px solid black;
  border-radius: 12px;
  padding: 1vh;
}
#social #links{
  padding: 1vh;
  margin: 1vh;
  
}
#social #links i{
  font-size: 2rem;
  color: rgba(0,0,0,0.7);
  margin: 1vh;
}
#social #links i:nth-child(1):hover{
  color: rgba(200,0,0,0.7);
  cursor: pointer;
}
#social #links i:nth-child(2):hover{
  color: rgba(20,40,200,0.7);
  cursor: pointer;
}
#social #links i:nth-child(3):hover{
  color: rgba(20,40,255,0.7);
  cursor: pointer;
}
.container #toggle-contact{
  display: block;
  background: rgba(0,0,0,0.7);
  padding: 1vh;
  border-radius: 12px;
}
.contact-link{
  color: rgba(255,255,255,0.5);
  text-decoration: none;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

}
.container #pages{
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.container #pages span{
  margin: 1vh;
}
.fa-address-card{
  font-size: 2rem;
  color: rgba(0,0,0,0.4);
  margin: 1vh;
}
.fa-code{
  font-size: 2rem;
  color: rgba(0,0,0,0.4);
  margin: 1vh;
}
#pages span{
  font-size: 1.5rem;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-weight: 400;
}
.fa-code:hover{
  color: rgba(0,0,0,0.8);
}
.fa-address-card:hover{
  color: rgba(0,0,0,0.8);
}