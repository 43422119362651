*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
html{
    font-size: 100%;
}
.form{
    width: 100%;
    height: 100vh;
    background: linear-gradient(30deg,rgba(90,50,200,0.7),rgba(0,200,150,0.7));
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.form span{
    font-size: 2rem;
    color: rgba(0,0,0,0.4);
}
.form h2{
    font-size: 1rem;
    font-weight: 200;
    color: rgba(255,255,255,0.4);
    margin: 1vh;
}
.form input[type='text']{
    padding: 1.5vh;
    margin: 1vh;
    border-radius: 12px;
    border: none;
    outline: none;
}
.form textarea{
    padding: 1vh;
    margin: 1vh;
    border-radius: 12px;
    border: none;
    outline: none;
    resize: none;
    height: 150px;
}
.form input[type='email']{
    padding: 1.5vh;
    margin: 1vh;
    border-radius: 12px;
    border: none;
    outline: none;
}
input[type='submit']{
    padding: 1vh;
    border-radius: 5px;
    outline: none;
    border: none;
    cursor: pointer;
    width: 100px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: rgba(0,0,0,0.4);
}
input[type='submit']:hover{
    color: rgba(0,0,0,0.8);
}
.toggle-home{
    display: block;
    width: max-content;
    background: rgba(0,0,0,0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(10vh);
    border-radius: 10px;
}
.home-link{
    text-decoration: none;
    padding: 1vh;
    font-size: 1rem;
    color: rgba(255,255,255,0.4);
}